<template>
    <div v-if="this.pageData && this.newsData" class="page-news">
        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" :menuparent="formatdate" />
        <main>
            <section class="main-content" v-if="this.newsData.attributes">
                <div class="container">

                    <div v-html="newsData.attributes.field_description_w_summary.value"></div>

                    <div class="margin attachments-container" v-if="newsData.field_attachment.length">
                        <h3>Attachments</h3>
                        <a v-for="(att, ind) in newsData.field_attachment" :key="'attach'+ind"
                            class="attachments" :href="endpoint + att.attributes.uri.url"
                            download target="_blank"> {{ getAttDescription(ind) }} </a>
                    </div>

                    <p class="image">
                        <img :src="newsimg" alt="">
                    </p>
                </div>
            </section>
        </main>
    </div>
</template>
<script>
import { fetchRoutes, fetchNodes } from '../../libs/drupalClient'
import HeaderIntroImage from '../../components/layout-blocks/HeaderIntroImage.vue'
    export default {
        name: 'news',
        components: {
            HeaderIntroImage
        },
        data: () => {
            return {
                pageData: {
                    attributes: {
                        title: ""
                    },
                    field_banner_image: {
                        attributes: {
                            uri: {
                                url: ""
                            }
                        }
                    }
                },
                routesData: null,
                currentNid: "",
                newsData: null
            }
        },
        computed: {
            newsimg() {
                if(this.newsData)
                    return process.env.VUE_APP_ENDPOINT + this.newsData.field_image.attributes.uri.url
                else 
                    return ''
            },
            objecttitle() {
                return this.$route.params.title
            },
            formatdate() {
                if(this.newsData)
                    return new Date(this.newsData.attributes.field_date).toLocaleString('en', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })
                else 
                    return ''
            },
            endpoint (){
                return process.env.VUE_APP_ENDPOINT
            }
        },
        methods: {
            getAttDescription(i) {
                var d = this.newsData.relationships.field_attachment.data[i].meta.description
                if(d=='')
                    d = this.newsData.field_attachment[i].attributes.filename
                
                return d
            }
        },
        async mounted() {
            await fetchRoutes().then(res => {
                this.routesData = res.routes
                var t = this.routesData.filter(rr => rr.url_check==this.objecttitle)

                if(t.length>0) {
                    this.currentNid = t[0].nid
 
                    fetchNodes('news', {
                        include: ['field_image', 'field_attachment'],
                        filters: [{
                            key: 'drupal_internal__nid',
                            value: this.currentNid
                        }]
                    }).then(res => {
                        this.newsData = res[0]
                        this.pageData.attributes.title = this.newsData.attributes.title
                        //console.log(this.newsData)
                     })
                } else {
                    this.$router.push('/not-found')
                }
            })
        },
        watch: {
            objecttitle() {
                fetchRoutes().then(res => {
                    this.routesData = res.routes
                    var t = this.routesData.filter(rr => rr.url_check==this.objecttitle)
                    
                    if(t.length>0) {
                        this.currentNid = t[0].nid
 
                        fetchNodes('news', {
                            include: ['field_image', 'field_attachment'],
                            filters: [{
                                key: 'drupal_internal__nid',
                                value: this.currentNid
                            }]
                        }).then(res => {
                            this.newsData = res[0]
                            this.pageData.attributes.title = this.newsData.attributes.title
                         })
                    } else {
                        this.$router.push('/not-found')
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.image {
    width: 100%;
    img {
        width: 100%
    }
}
</style>